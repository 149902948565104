/*** Style generique ***/
body,
button,
fieldset,
form,
html,
textarea {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
}

body,
html {
  scroll-behavior: smooth;
}

html {
  font-size: 62.5%;
  height: 100%;
}

body {
  font-size: 1.6rem;
}
.font-big {
  font-size: 2rem;
  line-height: 1.3;
}

body,
body.mce-content-body {
  color: $color10;
}

/* Saut à la ligne CMS et BUILDER EditeurTexte */
.clear {
  clear: both;
}

.scroller {
  overflow-x: hidden;
}

.aheight {
  display: inline-block;
  line-height: 1.2;
  vertical-align: middle
}

.mini,
.fontsize80 {
  font-size: 80%
}

.fontsize85 {
  font-size: 85%
}

.fontsize90 {
  font-size: 90%
}

.fontsize110 {
  font-size: 110%
}

.big,
.fontsize120 {
  font-size: 120%
}


.lowercase {
  text-transform: lowercase
}

.uppercase {
  text-transform: uppercase
}

.nocase {
  text-transform: none;
}

figure {
  margin: auto;
}

.hidden,
.mobile,
figcaption {
  display: none;
}

.desktop {
  display: block;
}

:-ms-input-placeholder,
::-moz-placeholder,
::-webkit-input-placeholder,
::placeholder {
  color: #91a1a1;
  font-style: italic;
  text-transform: none;
  font-weight: 700;
  font-size: 1.3rem;
}

img {
  max-width: 100%;
  height: auto;
}

.cms img {
  height: auto;
  border-radius: 10px;
}

.cms #grid-builder::before {
  content: '';
  display: block;
  width: 100%;
  max-width: 35rem;
  aspect-ratio: 234/250;
  background-image: url(../img/theme/picto-title.svg);
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  left: -13rem;
  top: 70rem;
  opacity: .15;
}

.icon,
.text {
  display: inline-block;
  vertical-align: middle;
}

// Liens
a, .lien-off {
  transition: all .3s ease-in-out;
  text-decoration: none;
}
.lien-off {
 cursor: pointer;
}
.cms a:not(.button),
.mce-content-body a:not(.button),
a,
.cms .lien-off:not(.button),
.mce-content-body .lien-off:not(.button),
.lien-off {
  color: $color9;
  cursor: pointer;
  &:active,
  &:hover{
    color: $color8;
  }
}

.cms a:not(.button):focus,
.cms a:not(.button):hover,
.mce-content-body a:not(.button):focus,
.mce-content-body a:not(.button):hover,
a:focus,
a:hover,
.cms .lien-off:not(.button):focus,
.cms .lien-off:not(.button):hover,
.mce-content-body .lien-off:not(.button):focus,
.mce-content-body .lien-off:not(.button):hover,
.lien-off:focus,
.lien-off:hover {
  text-decoration: none;
}

// Listes
.cms ol,
.cms ul,
.faq-answer ol,
.faq-answer ul,
.mce-content-body ol,
.mce-content-body ul {
  line-height: 1.5;
  list-style-type: inherit;
}

.cms ol li,
.cms ul li,
.faq-answer ol li,
.faq-answer ul li,
.mce-content-body ol li,
.mce-content-body ul li {
  list-style-position: inside;
  margin: .4rem auto .4rem 2rem;
  @media (max-width: $breakpoint-sm) {
    margin-left: 0;
  }
}

.cms ol,
.faq-answer ol,
.mce-content-body ol {
  list-style-type: decimal;
}

.liste li {
  list-style-type: square;
  list-style-position: inside;
  margin-left: 1rem;
  padding: .2rem 0;
}

// Tables
.table {
  display: table;
  border: .1rem solid #e3e3e3;
  border-collapse: collapse;
  font-size: 1.1rem;
  background-color: $color1;
  color: #3d3d3d;
}

.table-container {
  width: 87vw;
  overflow-y: auto;
  overflow-x: auto;
}

.table td,
.table th {
  border: .1rem solid #e3e3e3;
  padding: .5rem 1rem;
}

.table th {
  padding: 1rem;
}

.td {
  display: table-cell;
}

// Classes génériques + classe zoom pour fancybox
.cms {
  .box-shadow .content img{
    box-shadow: 0 .5rem 1.5rem rgba(0, 0, 0, .5);
  }

  .vertical-center .box {
    display: flex;
    align-items: center;
  }
}

/*** /Style generique ***/
.grid-builder {
  flex: 1 auto;
}
.grid-builder-content .row {
  margin: 0 auto 2rem auto;
  padding: 2rem;
  overflow: hidden;
}

#cms .scroller > .content {
  margin-top: 5rem;
}

#cms #bloc {
  padding: 2rem 2rem 0;
}

.page-cms .cms{
  line-height: 1.8;
}

#cms .content .container {
  margin: 0 auto;
  width: 114rem;
  overflow: hidden;
  background-color: $color1;
  min-height: 35rem;
}

#cms a.bx-next,
#cms a.bx-prev {
  color: $color1;
}

.page-cms .vertical-center {
  display: flex;
  align-items: center;

  > div {
    width: 100%;
  }
}

/* Slider */
.builder-slider{
  .slick-prev{
    left: 3.5rem;
  }
  .slick-next{
    right: 3.5rem;
  }
  .islide-content{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
}

/* Vidéo */
.type-video {
  .ratio-video{
    aspect-ratio: 590/315;
    iframe{
      width: 100%;
      height: 100%;
    }
  }
}

/* Tableau */
.type-texte table{
  tr td:first-child,
  tr:first-child{
    font-weight: 700;
    background-color: #ddd;
  }
  td{
    border: .1rem solid #aaa;
  }
}

.abs_container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

/*---------------*/
/***** Bubba *****/
/*---------------*/
.effect-bubba {
  background: #232e3f; }

.effect-bubba img {
  opacity: 0.9;
  transform: scale(1);
  -webkit-transition: opacity 0.35s, transform 0.35s, filter 0.35s;
  transition: opacity 0.35s, transform 0.35s, filter 0.35s; }

.effect-bubba:hover img {
  opacity: 0.2;
  transform: scale(1.12);
  -ms-filter: blur(4px);
  -webkit-filter: blur(4px);
  filter: blur(4px); }

.effect-bubba .overlay::before,
.effect-bubba .overlay::after {
  position: absolute;
  top: 30px;
  right: 30px;
  bottom: 30px;
  left: 30px;
  content: '';
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s; }

.effect-bubba .overlay::before {
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  -webkit-transform: scale(0, 1);
  transform: scale(0, 1); }

.effect-bubba .overlay::after {
  border-right: 1px solid #fff;
  border-left: 1px solid #fff;
  -webkit-transform: scale(1, 0);
  transform: scale(1, 0); }

.effect-bubba .title {
  position: absolute;
  width: calc(100% - 5em);
  bottom: 60%;
  left: 2.5em;
  margin-top: -30px;
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(0, 20px, 0);
  transform: translate3d(0, 20px, 0); }

.effect-bubba .description {
  position: absolute;
  width: calc(100% - 5em);
  top: 40%;
  left: 2.5em;
  margin-top: 10px;
  text-align: center;
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(0, 20px, 0);
  transform: translate3d(0, 20px, 0); }

.effect-bubba .event {
  position: absolute;
  width: calc(100% - 4em);
  bottom: 3em;
  margin-top: 0;
  text-align: center;
  opacity: 0;
  z-index: 11;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(0, 20px, 0);
  transform: translate3d(0, 20px, 0); }

.effect-bubba .event .icon_container {
  width: 3em;
  height: 3em;
  line-height: 3em;
  display: inline-block;
  border-radius: 50%;
  border: 2px solid #ffffff;
  color: #ffffff;
  position: relative;
  z-index: 11;
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }

.effect-bubba .event .icon_container:hover {
  background: rgba(255, 123, 0, 0.7);
  border-color: rgba(255, 123, 0, 0.7); }

.effect-bubba:hover .overlay::before,
.effect-bubba:hover .overlay::after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1); }

.effect-bubba:hover .title,
.effect-bubba:hover .description,
.effect-bubba:hover .event {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.real_item {
  float: left;
  width: calc(100% / 3);
  height: 0;
  margin: 0;
  padding: calc(100% / 3) 0 0;
  position: relative;
  overflow: hidden; }

.real_item.height-x2 {
  padding-top: calc(100% / 3); }

.real_item.width-x2 {
  width: calc(100% / 3); }

@media screen and (max-width: 599px) {
  .real_item {
    float: left;
    width: calc(100% / 2);
    height: 0;
    margin: 0;
    padding: calc(100% / 2) 0 0;
    position: relative;
    overflow: hidden; }
  .real_item.height-x2 {
    padding-top: calc(100% * 2 / 3); }
  .real_item.width-x2 {
    width: calc(100% * 2 / 3); } }

@media screen and (min-width: 600px) and (max-width: 1025px) {
  .real_item {
    width: calc(100% / 3);
    padding-top: calc(100% / 3); }
  .real_item.height-x2 {
    padding-top: calc(100% / 1.5); }
  .real_item.width-x2 {
    width: calc(100% / 1.5); } }

@media screen and (min-width: 1026px) and (max-width: 1250px) {
  .real_item {
    width: calc(100% / 4);
    padding-top: calc(100% / 4); }
  .real_item.height-x2 {
    padding-top: calc(100% / 2); }
  .real_item.width-x2 {
    width: calc(100% / 2); } }
