/* Steps panier */
.step-c {
  margin-bottom: 4rem;
  border-top: .1rem solid #e7e7e7;
  border-bottom: .1rem solid #e7e7e7;

  ul {
    display: flex;
    padding-left: 0;
    margin-bottom: 0;

    li {
      @include flex-center;
      width: 25%;
      height: 5rem;
      text-align: center;
      border-left: .1rem solid #e7e7e7;

      &:last-child {
        border-right: .1rem solid #e7e7e7;
      }

      &.current {
        color: $color1;
        background-color: #303030;
      }

      a:hover {
        color: $color8;
      }
    }
  }
}

/* Calcul des largeurs des champs sur la step 1*/
$widthImg: 8rem;
$widthQt: 17rem;
$widthUnitPrice: 11rem;
$widthTotalPrice: 10rem;
$widthDelete: 3rem;
$widthNom: calc(100% - (#{$widthImg} + #{$widthQt} + #{$widthUnitPrice} + #{$widthTotalPrice} + #{$widthDelete}));
$widthSummaryTitle: calc(100% - (#{$widthUnitPrice} + #{$widthTotalPrice} + #{$widthDelete}));
$widthLabelUnitPrice: $widthUnitPrice;

.panier-listing {
  .line-summary {
    display: flex;
    align-items: center;
    margin-bottom: -.8rem;
    font-size: 1.4rem;
    font-weight: 700;
    color: $color10;

    .h2 {
      width: $widthSummaryTitle;
      font-size: 2.2rem;
      font-weight: 700;
      color: $color6;
      margin: 0;
      text-transform: uppercase;
    }

    .unit-price-label {
      width: $widthLabelUnitPrice;
    }
  }

  .product-line-c {
    display: flex;
    justify-content: space-between;

    .img-produit-panier {
      margin-left: 0;
      margin-right: 0;
      width: $widthImg;

      img {
        height: 8rem;
        width: 8rem;
        object-fit: cover;
      }
    }

    .nom-produit-panier {
      width: $widthNom;
      padding-left: 1rem;

      a {
        font-size: 1.6rem;
        font-weight: 700;
        text-transform: uppercase;
        color: $color8;

        &:hover {
          color: $color6;
        }
      }

      .ref-panier {
        text-transform: uppercase;
      }
    }

    .ref-panier,
    .stock,
    .qt-label {
      font-size: 1.4rem;
      font-weight: 600;
      color: $color10;
    }

    .qt-container {
      width: $widthQt;
      padding-left: 1rem;

      .stock {
        justify-content: flex-start;
        margin-bottom: 1.4rem;
      }

      .qt-label {
        margin-right: .8rem;
      }

      .qt-block .input-qty-cart,
      .qt-block .qt-manage {
        height: 4rem;
      }

      .qt-block .qt-manage.less-qt {
        padding-bottom: .5rem;
      }
    }

    .unit-price {
      width: $widthUnitPrice;
      font-size: 1.4rem;
      font-weight: 700;
      color: $color10;
    }

    .total-price {
      width: $widthTotalPrice;
      font-size: 1.8rem;
      font-weight: 700;
      color: $color10;
    }

    .delete {
      width: $widthDelete;
      text-align: center;

      .icon-delete {
        cursor: pointer;
        transition: color .3s ease-in-out;

        &:hover {
          color: $color9;
        }
      }
    }
  }
}

.panier-recap-content {
  background-color: $color4;
  padding: 2rem 2.5rem;

  .h2 {
    font-size: 2.2rem;
    text-transform: uppercase;
    margin-top: 0;
  }

  .product-line-recap {
    display: flex;
    justify-content: space-between;
    font-size: 1.6rem;
    font-weight: 600;
    color: $color10;

    .maxw300 {
      line-height: 1.2;
      margin-top: .5rem;
    }
  }

  .amount-total-line {
    align-items: center;
    margin-top: 3rem;
    margin-bottom: 1rem;

    .amount-total {
      font-size: 2.3rem;
      font-weight: 700;
    }
  }
}

.panierCodesRemises {
  margin-top: 2.4rem;

  > div {
    font-size: 1.4rem;
    font-weight: 600;
    color: $color10;
  }
}

.cta-recap-content {
  margin-top: 1.5rem;
  margin-bottom: 5rem;

  .sauvegarde-panier {
    margin-bottom: 2rem;

    > div {
      font-size: 1.4rem;
      font-weight: 600;
      color: $color10;
    }
  }

  .cta-recap-submit a {
    width: 100%;
    font-size: 1.8rem;
    font-weight: 700;
    letter-spacing: .15rem;
    border-color: $color8;

    &:hover {
      border-color: $color6;
    }
  }
}

.form-recap {
  display: flex;
  align-items: center;

  input {
    height: 5rem;

    &[type="text"] {
      width: calc(100% - 17rem);
      padding-left: 1.5rem;
      font-size: 1.4rem;
      font-weight: 600;
      text-transform: uppercase;

      &::placeholder {
        color: $color6;
      }
    }

    + input {
      width: 16rem;
      text-align: center;
      padding: 0;
      margin-left: 1rem;
    }
  }
}

.bribe-recap {
  p {
    font-size: 1.6rem;
    font-weight: 700;
    background-color: $color4;
    padding: 3.5rem 3rem;
    margin-bottom: 7.6rem;
  }
}

.choix-adresses {
  border: .1rem solid $color4;
  padding: 2rem;

  .h3 {
    color: $color8;
    background-color: $color4;
    text-align: center;
    font-size: 1.8rem;
    padding-top: 1.4rem;
    padding-bottom: 1.4rem;
    margin-bottom: 2rem;
  }

  .icon {
    font-size: 3rem;
    margin-right: 1rem;
  }

  .collapse-box input[type="radio"] {
    display: none;

    + label {
      transition: color .3s ease-in-out;

      &:focus,
      &:hover {
        color: $color9;
      }
    }

    &:checked + label {
      color: $color8;
    }
  }
}

.choix-transporteur,
.choix-mode-paiement {
  .h3 {
    margin-bottom: 2rem;
  }

  ul {
    list-style-type: none;
    padding-left: 0;

    li {
      margin: 0 auto 1rem;
      border-radius: .3rem;
      max-width: 50rem;

      input {
        display: none;
      }

      label {
        @include flex-between;
        padding-left: 2rem;
        padding-right: 2rem;
        margin-bottom: 0;
        min-height: 5.4rem;
        font-weight: 700;
        border: .1rem solid $color9;
        cursor: pointer;
        transition: background-color .3s ease-in-out;

        &:hover {
          background-color: $color8;
        }
      }

      input:checked + label {
        color: $color1;
        background-color: $color8;
        border-color: $color8;

        svg {
          fill: $color1;
        }
      }
    }
  }
}

.choix-mode-paiement {
  ul li {
    max-width: 42rem;

    em {
      display: block;
      margin-top: .2rem;
      padding-left: 1.2rem;
      font-size: 1.2rem;
      font-style: italic;
    }

    svg {
      width: 4rem;
    }
  }
}

@media (max-width: $breakpoint-xs) {
  .step-c ul {
    flex-direction: column;

    li {
      width: 100%;
    }
  }
}