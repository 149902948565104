@mixin title-global {
  word-wrap: normal;
  hyphens: none;
  line-height: 1;
}

@mixin h1 {
  position: relative;
  font-family: 'ITCErasStd', sans-serif;
  color: $color8;
  font-size: 3.6rem;
  font-weight: 600;
  margin: 1rem 0 .5rem;
  line-height: 1;
  text-align: center;
  @media (max-width: $breakpoint-md) {
    font-size: 2.5rem
  }
}

@mixin h2 {
  font-size: 3.2rem;
  font-family: 'Sora', sans-serif;
  color: $color10;
  margin: .6rem 0;
  font-weight: 700;
  line-height: 1;
  @media (max-width: $breakpoint-md) {
    font-size: 2.2rem
  }
}

@mixin h3 {
  font-size: 2.8rem;
  font-family: 'Sora', sans-serif;
  color: $color6;
  margin: .6rem 0;
  font-weight: 500;
  line-height: 1;
  @media (max-width: $breakpoint-md) {
    font-size: 2rem
  }
}

@mixin h4 {
  font-family: 'Stora', sans-serif;
  font-size: 2.2rem;
  font-weight: 700;
  color: $color10;
  margin: .6rem 0;
  line-height: 1;
  @media (max-width: $breakpoint-md) {
    font-size: 2rem
  }
}

@mixin h5 {
  font-size: 2rem;
  color: $color6;
  margin: .6rem 0;
  font-weight: 400;
  line-height: 1;
  @media (max-width: $breakpoint-md) {
    font-size: 2rem
  }
}

@mixin h6 {
  font-size: 2rem;
  color: $color6;
  margin: .6rem 0;
  font-weight: 400;
  line-height: 1;
  @media (max-width: $breakpoint-md) {
    font-size: 2rem
  }
}
