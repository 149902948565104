h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  @include title-global;
}

h1,
.h1 {
  @include h1;
}

h2,
.h2 {
  @include h2;
}

h3,
.h3 {
  @include h3;
}

h4,
.h4 {
  @include h4;
}

h5,
.h5 {
  @include h5;
}

h6,
.h6 {
  @include h6;
}

.main-title {
  margin: 3rem 0;
}


.title-picto{
  display: flex;
  align-items: center;
  margin-bottom: 1.2rem;
  &::before{
    content: '';
    display: inline-block;
    background-image: url("../img/theme/picto-title.svg");
    aspect-ratio: 234/250;
    background-repeat: no-repeat;
    background-size: contain;
    width: 45px;
    margin-right: 1.2rem;
  }
  p{
    margin-bottom: 0;
  }
}