.searchfaq{
  @include flex-center;
  margin-top: 2rem;
  margin-bottom: 3rem;
  input{
    height: 5rem;
    width: 100%;
    max-width: 30rem;
    border: none;
    padding-left: 2rem;
    background-color: $color6;
    outline: 0;
  }
  button{
    height: 5rem;
    color: $color1;
    font-size: 2.6rem;
    padding: 0 1.6rem;
    line-height: 0;
    border: none;
    transition: color .3s;
    &:hover{
      color: #000;
    }
  }
}

.faq_content {
  position: relative;
  min-height: 20rem;
  margin-top: 5rem;
  padding-bottom: 10rem;
  .collapsing {
    transition: height .2s ease-in-out;
  }

  .container {
    @include flex-center;

    .pattern {
      @include flex-center;
      min-width: 8rem;
      min-height: 36rem;
      background: url("#{$base-img-url}/faq.svg") no-repeat center center;
    }

    .text_content {
      @include flex-center;
      flex-direction: column;
      flex: 1;

      ul {
        margin: 0 0 0 6rem;
        padding: 0;
        list-style: none;
        width: 100%;

        display: flex;
        justify-content: center;
        flex-direction: column;

        li {
          margin-bottom: 1.5rem;
          border-radius: 1.6rem;
          padding: 1rem 3rem;
          background: #eee;

          .f-link {
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid #ccc;
            padding: 1.5rem 2rem 1.5rem 0;
            > .icon {
              transition: transform .3s ease-in-out;
            }
            &:not(.collapsed) > .icon {
              transform: rotate(45deg);
            }
          }
          ul {
            margin: 0;
            .faq_reponse{
              margin-top: 1.5rem;
              ul{
                list-style: initial;
                padding-left: 1.5rem;
                li{
                  padding: 0;
                  margin-bottom: .5rem;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: $breakpoint-md) {
  .faq_content{
    .collapsing{
      transition: none;
    }
    .container{
      .pattern{
        display: none;
      }
      .text_content ul{
        margin: 0;
        li{
          padding: 1.5rem;
          .f-link{
            padding-right: 1rem;
          }
        }
      }
    }
  }

}

@media (max-width: $breakpoint-sm) {
  .faq_content .container .text_content ul li {
    padding: 1rem;
  }
}
