.bandeau-header-c{
  position: relative;
  aspect-ratio: 1922/319;
  min-height: 20rem;
  max-width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  @include flex-center;
  &::before{
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(270deg, rgba(9, 21, 41, 0.8) 0%, rgba(9, 21, 41, 0) 100%), rgba(9, 21, 41, 0.2);
    opacity: 0.6;
  }
  &::after{
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    display: block;
    background-image: url("../img/theme/wave.svg");
    aspect-ratio: 1920/117;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .bandeau-header-contenu{
    @include flex-center;
    text-align: center;
    margin-top: -5rem;
  }
  .icon{
    position: relative;
    color: #75CC3D;
    font-size: 8rem;
    margin-right: 3rem;
  }
  .h1{
    font-family: 'ITCErasStd', sans-serif;
    font-size: 5.2rem;
    color: #fff;
  }
  .h3{
    position: relative;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 2.8rem;
    color: #fff;
  }
}

@media (max-width: $breakpoint-md) {
  .bandeau-header-c{
    .h1{
      font-size: 3.8rem;
    }
    .bandeau-header-contenu {
      margin-top: -2rem;
    }
  }
}

@media (max-width: $breakpoint-sm) {
  .bandeau-header-c{
    .h1 {
      font-size: 3rem;
    }
    .h3{
      font-size: 2rem;
    }
    .icon{
      font-size: 6rem;
      margin-right: 1.5rem;
    }
  }
}

@media (max-width: $breakpoint-xs) {
  .bandeau-header-c{
    .h1{
      font-size: 2.5rem;
    }
    .h3{
      font-size: 1.8rem;
    }
    .icon{
      margin-right: .8rem;
    }
    .bandeau-header-contenu {
      flex-direction: column;
      line-height: 0;
      margin-top: -1rem;
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
}
