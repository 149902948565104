/* Domaines d'activité */
.domaines-activite{
  margin-top: 10rem;
  position: relative;
  &::after{
    content: '';
    display: block;
    width: 100%;
    max-width: 35rem;
    aspect-ratio: 234/250;
    background-image: url('../img/theme/picto-title.svg');
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    left: -13rem;
    bottom: -8rem;
    opacity: 0.15;
  }
}
.blue-block-list{
  display: flex;
  justify-content: center;
  margin-top: 4rem;
  margin-left: -1.2rem;
  margin-right: -1.2rem;
  .blue-block{
    position: relative;
    z-index: 1;
    margin-left: 1.2rem;
    margin-right: 1.2rem;
    width: 20%;
    .h4{
      height: 4.4rem;
    }
    img{
      width: 3.8rem;
      margin-bottom: .5rem;
    }
  }
  .blue-block:nth-child(2){
    img {
      width: 5rem;
      height: 3.8rem;
      object-fit: cover;
    }
  }
}

/* Domaines Savoir-faire */
.savoir-faire{
  position: relative;
  margin-top: 10rem;
  padding-top: 6rem;
  padding-bottom: 12rem;
  background-repeat: no-repeat;
  background-size: cover;
  &::after{
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    display: block;
    background-image: url("../img/theme/wave.svg");
    aspect-ratio: 1920/117;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .desc{
    margin-bottom: 3rem;
  }
}

.savoir-faire-liste{
  display: flex;
  justify-content: space-between;
  margin-left: -1.2rem;
  margin-right: -1.2rem;
  .savoir-faire-block{
    position: relative;
    margin-left: 1.2rem;
    margin-right: 1.2rem;
    padding-bottom: 3rem;
    border: .2rem solid transparent;
    border-radius: 1.4rem;
    overflow: hidden;
    .h4{
      font-family: 'Sora', sans-serif;
      font-size: 1.7rem;
      color: #fff;
      font-weight: 700;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 1.1rem 1rem 1.2rem;
      margin-bottom: 0;
      text-align: center;
      z-index: 1;
      transition: all .3s;
      background-color: #000;
    }
    img{
      border-radius: 1.2rem;
    }
    &:hover{
      .h4{
        background-color: $color8;
      }
    }
  }
}

/* Projet sur mesure */
.sur-mesure{
  position: relative;
  margin-top: 8rem;
  &::after{
    content: '';
    display: block;
    width: 100%;
    max-width: 35rem;
    aspect-ratio: 234/250;
    background-image: url('../img/theme/picto-title-grey.svg');
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    top: 12rem;
    right: -8rem;
  }
}

.card-etape{
  width: calc(50% - 1.2rem);
  border: .3rem solid #F1F7E6;
  border-radius: 1.6rem;
  margin-bottom: 2.4rem;
  padding: 1.6rem;
  .num-etape{
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Sora', sans-serif;
    font-size: 3.2rem;
    font-weight: 700;
    color: #75CC3D;
    border-radius: 4.8rem;
    background-color: #F1F7E6;
    height: 5.6rem;
    width: 5.3rem;
  }
  .text-etape{
    color: #9BA2AF;
    .h3{
      font-family: 'Sora', sans-serif;
      color: #091529;
      font-size: 2rem;
      font-weight: 700;
      margin-top: 1rem;
      margin-bottom: .8rem;
    }
  }
  &:first-child{
    background-color: #F1F7E6;
    .num-etape{
      background-color: #fff;
    }
  }
}

.sur-mesure-contenu{
  display: flex;
  justify-content: space-between;
  margin-top: 3rem;
  > div{
    width: calc(50% - 5rem);
  }
  .liste-etapes{
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .button-c{
      width: 100%;
      text-align: center;
      .button{
        padding: 2.5rem 7.4rem;
      }
    }
    &::after{
      content: '';
      position: absolute;
      bottom: 9.5rem;
      left: 0;
      right: 0;
      height: 12rem;
      background: linear-gradient(180deg, #FFFCF9 0%, rgba(255, 252, 249, 0) 100%);
      transform: matrix(1, 0, 0, -1, 0, 0);
    }
  }
}

@media (max-width: $breakpoint-lg) {
  .domaines-activite{
    margin-top: 5rem;
  }
  .blue-block-list .blue-block{
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .savoir-faire{
    margin-top: 5rem;
    padding-top: 3rem;
    padding-bottom: 8rem;
  }
  .sur-mesure{
    margin-top: 3rem;
  }
  .sur-mesure-contenu{
    > div {
      width: calc(50% - 1rem);
    }
    .liste-etapes .button-c .button {
      padding: 2rem 5rem;
    }
  }
}

@media (max-width: $breakpoint-md) {
  .blue-block-list{
    flex-wrap: wrap;
    .blue-block{
      width: calc(100% / 3 - 1rem);
      margin-bottom: 1rem;
    }
  }
  .savoir-faire-liste{
    flex-wrap: wrap;
    .savoir-faire-block{
      margin-left: .6rem;
      margin-right: .6rem;
      margin-bottom: 1.2rem;
      width: calc(50% - 1.2rem);
    }
  }
  .sur-mesure-contenu{
    flex-wrap: wrap;
    > div{
      width: 100%;
    }
    .sur-mesure-image{
      display: block;
      order: -1;
      margin-bottom: 2rem;
      max-width: 40rem;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

@media (max-width: $breakpoint-xs) {
  .blue-block-list .blue-block{
    width: 100%;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
  .savoir-faire-liste .savoir-faire-block{
    width: 100%;
  }
  .card-etape{
    width: 100%;
    margin-bottom: 1rem;
    padding: .8rem;
  }
}
