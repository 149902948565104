.rassurances {
  padding-top: 8rem;
  padding-bottom: 8rem;
  margin-top: 12rem;
  background-color: #EEF2FA;
  .rassurance-card-liste {
    @include flex-between;
    margin-left: -1rem;
    margin-right: -1rem;
    .rassurance-card{
      width: calc(100% - 2rem);
      margin-left: 1rem;
      margin-right: 1rem;
      text-align: center;
      img{
        max-height: 9.4rem;
        margin-bottom: 2rem;
      }
      .h3{
        color: $color10;
        font-weight: 700;
        margin-bottom: 1.2rem;
      }
      .desc{
        color: #9BA2AF;
      }
    }
  }
}

@media (max-width: $breakpoint-lg) {
  .rassurances{
    padding-top: 4rem;
    padding-bottom: 4rem;
    margin-top: 5rem;
    .rassurance-card-liste .rassurance-card{
      img{
        margin-bottom: 1rem;
      }
      .h3 {
        font-size: 2rem;
      }
      .desc {
        font-size: 1.5rem;
      }
    }
  }
}

@media (max-width: $breakpoint-sm) {
  .rassurances{
    padding-bottom: 2rem;
    .rassurance-card-liste{
      flex-wrap: wrap;
      .rassurance-card{
        width: calc(50% - 2rem);
        margin-bottom: 2rem;
      }
    }
  }
}

@media (max-width: $breakpoint-xs) {
  .rassurances .rassurance-card-liste .rassurance-card{
    width: 100%;
  }
}