/* Affichage date et tags */
.tag-label,
.tag-actu {
  display: inline-block;
  font-size: 1.2rem;
  padding: .2rem .5rem;
  color: $color1;
  background-color: $color8;

  + .tag-label {
    margin-left: .2rem;
  }
}

.date-article {
  position: absolute;
  top: 0;
  right: 0;
  color: $color1;
  background-color: $color8;
  padding: .2rem .6rem;
  z-index: 1;
}

/*** Liste des news et références ***/

.liste-articles article {
  margin-bottom: 2rem;

  .article-card {
    height: 100%;
    background-color: #eee;

    .link-article-img {
      display: block;
      position: relative;

      img{
        height: 100%;
        width: 100%;
        object-fit: contain;
        aspect-ratio: 1;
      }

      .liste-tags {
        position: absolute;
        left: .4rem;
        bottom: .4rem;

        .tag-label {
          font-size: 1.2rem;
          padding: .2rem .6rem;

          + .tag-label {
            margin-left: 0;
          }
        }
      }
    }

    .article-card-content {
      height: 15rem;
      padding: .5rem 1rem 1rem;

      .h3{
        font-size: 2.4rem;
        margin: 1.2rem 0;
      }
      .desc {
        color: $color8;
      }
    }

    .button {
      font-size: 1.8rem;
      margin-top: 1rem;
      margin-bottom: 1rem;
      padding-top: 1.2rem;
      padding-bottom: 1.2rem;
    }
  }
}

/*** Article actif ***/
.article-content {
  margin-top: 3rem;
  margin-bottom: 4rem;

  .img-article-c {
    position: relative;

    .date-article {
      top: 1rem;
      right: 1rem;
    }
  }

  .article-txt {
    margin-top: 2rem;
  }
}
