@font-face {
  font-family: 'ITCErasStd';
  src: local('ITCErasStd Demi'), local('ITCErasStd-Demi'),
  url('../fonts/ITCErasStd-Demi.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat Medium'), local('Montserrat-Medium'),
  url('../fonts/Montserrat-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'),
  url('../fonts/Montserrat-SemiBold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat Bold'), local('Montserrat-Bold'),
  url('../fonts/Montserrat-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sora';
  src: local('Sora Regular'), local('Sora-Regular'),
  url('../fonts/Sora-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sora';
  src: local('Sora SemiBold'), local('Sora-SemiBold'),
  url('../fonts/Sora-SemiBold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sora';
  src: local('Sora Bold'), local('Sora-Bold'),
  url('../fonts/Sora-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}


/* desactive pour le backo */
/*
html body:not([class*="breakpoint"]) {

  .roboto {
    font-family: 'Roboto', sans-serif;
  }
  .pressstyle {
    font-family: 'Press Style', fantasy, sans-serif;
  }

}
*/