/* Footer */
#footer {
  padding-top: 6rem;
  padding-bottom: 2rem;
  color: #fff;
  background-color: $color10;

  .footer-c {
    display: flex;
    justify-content: space-between;
    max-width: 174rem;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-left: auto;
    margin-right: auto;

    .footer-logo {
      max-width: 22rem;

      img {
        max-width: 18rem;
        margin-bottom: 1rem;
      }
    }

    .menu-footer {
      ul {
        list-style-type: none;
        padding-left: 0;
      }

      .level1 {
        display: flex;

        > li {
          margin-left: 3rem;
          margin-right: 3rem;
          .no-link,
          .lien-off,
          a{
            color: #fff;
          }
          a,
          .lien-off{
            &:focus,
            &:hover{
              color: $color8;
            }
          }
        }
        .level2 li{
          margin-bottom: .8rem;
        }
      }
      .title{
        display: block;
        font-family: 'Sora', sans-serif;
        font-size: 2rem;
        font-weight: 700;
        margin-bottom: 1rem;
      }
    }
  }
}

.sub-footer {
  margin-top: 2rem;
  ul {
    list-style-type: none;
    display: flex;
    justify-content: center;
    padding-left: 0;

    li a,
    li .lien-off {
      color: $color1;
      font-size: 1.4rem;
      margin-left: 2.5rem;
      margin-right: 2.5rem;
      font-weight: 400;

      &:hover,
      &:focus {
        color: $color8;
      }
    }
  }
}

/* Signature Kyxar */
.kyxar {
  position: absolute;
  z-index: 9;
  white-space: nowrap;
  right: 1rem;
  margin-top: -3rem;
  padding: 0;
  line-height: 2.5rem;
  font-size: 1.1rem;
  color: #fff;
  a,
  .lien-off {
    color: #fff;
    font-size: 1.6rem;
    text-decoration: none;
    letter-spacing: .1rem;
    position: relative;

    .none {
      position: absolute;
      bottom: 3.8rem;
      right: 2.8rem;
      height: 0;
      overflow: hidden;
      color: $color9;

      span {
        display: inline-block;
        background-color: $color1;
        border-radius: .3rem;
        padding: .5rem 1rem;
        line-height: 1;
        font-weight: 300;
        font-size: 1rem;
      }
    }

    &:hover {
      color: $color9;

      .none {
        overflow: visible;
      }
    }
  }
}

@media (max-width: $breakpoint-xl) {
  #footer .footer-c .menu-footer .level1{
    flex-wrap: wrap;
    > li{
      width: calc(100% / 3 - 2.4rem);
      margin-left: 1.2rem;
      margin-right: 1.2rem;
      margin-bottom: 3rem;
    }
  }
}

@media (max-width: $breakpoint-md) {
  #footer .footer-c .menu-footer .title{
    font-size: 1.7rem;
  }
}

@media (max-width: $breakpoint-sm) {
  #footer .footer-c{
    flex-wrap: wrap;
    text-align: center;
    .footer-logo{
      width: 100%;
      margin-bottom: 3rem;
      max-width: 30rem;
      margin-left: auto;
      margin-right: auto;
    }
  }
  #footer .footer-c .menu-footer .level1 > li{
    width: calc(100% / 2 - 2rem);
    margin-left: 1rem;
    margin-right: 1rem;
    margin-bottom: 3rem;
  }
}

@media (max-width: $breakpoint-xs) {
  #footer .footer-c .menu-footer .level1 > li{
    width: 100%;
  }
  .sub-footer ul{
    flex-direction: column;
  }
}
