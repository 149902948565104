/* /!\ Completer les couleurs dans "Visualiser" et "Variables" */
/* Couleur pour visualiser rapidement */

$color1: #fff;
$color2: #000;
$color3: #ddd;
$color4: #ccc;
$color5: #999;
$color6: #666;
$color7: #333;

$color8: #0563FA; //Couleur principale
$color9: #75CC3D; //Couleur secondaire
$color10: #091529;
$color11: #EEF2FA;

/* Variables couleurs */
$colors: (
        "1": #fff,
        "2": #000,
        "3": #ddd,
        "4": #ccc,
        "5": #999,
        "6": #666,
        "7": #333,

        "8": #0563FA,
        "9": #75CC3D,
        "10": #091529,
        "11": #EEF2FA,
);

/* desactive pour le backo */
body:not([class*="breakpoint"]) {

  /* Genere automatiquement les styles generiques du projet en fonction des couleurs */
  @each $number-color, $color in $colors {

    /* color */
    .color#{$number-color},
    .color#{$number-color} a {
      color: $color;
    }

    /* background-color */
    .bg#{$number-color} {
      background-color: $color;
    }

    /* border color */
    .border#{$number-color} {
      border: .1rem solid $color;
    }

    /* separator color */
    .sep#{$number-color} {
      margin: 1.5rem 0;
      height: .1rem;
      background-color: $color;
    }

    /* button generique */
    /* Si blanc */
    @if ($color == $color1 or $color == $color4 or $color == $color5) {
      .button.button#{$number-color} {
        background-color: $color;
        color: $color7;
        border: .1rem solid $color;

        &:hover, &:focus {
          background-color: #333;
          color: $color;
          border: .1rem solid $color;
        }
      }
    } @else {
      .button.button#{$number-color} {
        background-color: $color;
        color: $color1;
        border: .1rem solid $color;

        &:hover, &:focus {
          background-color: $color1;
          color: $color;
          border: .1rem solid $color;
        }
      }
    }

  }
}


