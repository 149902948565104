.pagination {
  margin-top: 4rem;
  display: flex;
  li {
    margin-right: .5rem;
    margin-left: .5rem;
    list-style-type: none;

    &:not(.navig) {
      a {
        font-weight: bold;
        display: inline-block;
        padding: .7rem 1.7rem;
        font-size: 2rem;
        text-align: center;
        color: $color2;
        border-radius: .5rem;
        background: $color2;
      }

      a:hover,
      &.current a {
        color: $color1;
        background: $color8;
      }
    }

    &.navig {
      margin-right: .8rem;
      margin-left: .8rem;

      a {
        font-size: 2.5rem;
        color: $color2;

        &:hover {
          color: $color8;
        }

        .icon {
          @include flex-center;
        }
      }
    }
  }
}
