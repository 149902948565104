/* Slider avec slide affichée 1 par 1 */
.single-slider,
.single-slider .slide .img-slider {
  height: 61rem;
}

.single-slider {
  padding-left: 0;
  margin-bottom: 0;

  &::after{
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    display: block;
    background-image: url("../img/theme/wave.svg");
    aspect-ratio: 1920/117;
    background-repeat: no-repeat;
    background-size: contain;
  }


  .slide {
    position: relative;

    &::before{
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: linear-gradient(270deg, rgba(9, 21, 41, 0.8) 0%, rgba(9, 21, 41, 0) 100%), rgba(9, 21, 41, 0.2);
      opacity: .4;
    }

    img {
      width: 100%;
      object-fit: cover;
    }

    .islide-content {
      @include absolute-container;
      @include flex-center;

      .islide {
        padding: 2rem;
        text-align: center;

        .h1 {
          font-size: 8.4rem;
          color: #fff;
          margin-bottom: 1rem;
        }

        .h3 {
          color: #fff;
          font-size: 3.6rem;
          margin-bottom: 3rem;
        }

        img{
          max-width: 33rem;
          width: 100%;
          margin-left: auto;
          margin-right: auto;
          margin-bottom: 3rem;
        }

        .button {
          cursor: pointer;

          &:hover {
            color: $color8;
            background-color: $color1;
          }
        }
      }
    }
  }

  .slick-prev {
    left: 3.5rem;
  }

  .slick-next {
    right: 3.5rem;
  }

  .slick-next::before,
  .slick-prev::before {
    color: $color9;
  }

  .slick-dots {
    right: 0;
    bottom: -2.5rem;

    li button::before {
      background-color: $color9;
    }
  }
}

/* Slider avec plusieurs slides affichées */
.multi-slider {
  margin-left: -1rem;
  margin-right: -1rem;

  .slick-slide {
    position: relative;
    background-color: #eee;
    margin-left: 1rem;
    margin-right: 1rem;

    .islide-content {
      padding: .5rem 1rem 1rem;

      .desc {
        height: 7rem;
        overflow: hidden;
      }
    }
  }

  .slick-prev {
    left: -4rem;
  }

  .slick-next {
    right: -4rem;
  }
}

/* Slider avec des vignettes (page article et news) */

.slider-single,
.slider-single-news {
  .slider-nav-container {
    position: relative;
    .slider-nav-content,
    figure {
      @include absolute-container;
      @include flex-center;
      cursor: pointer;
    }
  }
}

.slider-nav,
.slider-nav-news {
  .slick-track {
    margin: 0 1rem 1rem;

    .slick-slide {
      margin-right: 1.5rem;

      img {
        object-fit: cover;
        @include box-shadow-light;
        cursor: pointer;
        height: 7.5rem;
        border: .2rem solid transparent;
        padding: .3rem;
      }
    }
  }

  .slick-slide.is-active img {
    border-color: $color8;
  }
}

.fancybox-thumbs__list a::before {
  border: 4px solid $color8;
}

.tag-actu {
  display: inline-block;
  color: $color1;
  background-color: $color8;
  padding: .1rem .3rem;
  margin-bottom: .4rem;
}

@media (max-width: $breakpoint-lg) {
  .single-slider,
  .single-slider .slide .img-slider{
    height: 40rem;
  }
  .single-slider .slide .islide-content .islide{
    .h1{
      font-size: 5rem;
      margin-bottom: 0;
    }
    .h3{
      font-size: 2.5rem;
      margin-bottom: 0;
    }
    img{
      margin-top: 1.5rem;
    }
  }
}

@media (max-width: $breakpoint-xs) {
  .single-slider,
  .single-slider .slide .img-slider{
    height: 30rem;
  }
  .single-slider .slide .islide-content .islide{
    .h1{
      font-size: 3rem;
    }
    .h3 {
      font-size: 2rem;
    }
    img{
      margin-bottom: 2rem;
    }
    .button{
      padding-left: 1rem;
      padding-right: 1rem;
      font-size: 1.7rem;
    }
  }
}
