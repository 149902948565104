.bloc-slider-nav {
  .slider-nav-container {
    text-align: center;

    .slider-nav-content {
      display: flex;
      background-size: contain;
    }

    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
      padding: 1rem;
    }
  }

  .gallery .slick-slide > div {
    margin: .5rem;
    height: 100%;
  }

  .slider-nav,
  .slider-nav-news {
    margin-left: .2rem;

    .slick-prev {
      left: -3rem;
    }

    .slick-next {
      right: -3rem;
    }

    .slick-track {
      margin: 0 -.4rem;
      margin-top: 1rem;
      padding-bottom: 1rem;

      .slick-slide {
        padding-left: .2rem;
        margin: 0 .4rem;
        cursor: pointer;
      }

      img {
        object-fit: contain;
      }
    }
  }
}

.info-produit {
  .h1 {
    font-size: 3.2rem;
    color: $color6;
    font-weight: 800;
    text-align: left;
    margin-bottom: 2rem;
  }

  p {
    margin-bottom: 0;
  }

  .reference {
    font-size: 1.4rem;
    text-transform: uppercase;
    font-weight: 600;
    color: $color6;
    margin-bottom: 2rem;
  }

  .stock {
    color: $color6;
    font-weight: 600;
    justify-content: flex-start;
    margin-top: 3rem;
  }
}

.achat-produit-c {
  font-size: 1.4rem;
  font-weight: 700;
  text-transform: uppercase;
  background-color: $color4;
  padding: 3rem;

  .bloc-prix {
    .prix {
      font-size: 3.2rem;
      font-weight: 400;
    }
  }

  #panier {
    margin-top: 2.6rem;

    .qt-submit-block {
      display: flex;
      justify-content: space-between;
    }

    button[type="submit"] {
      padding: .5rem 2.5rem;
      letter-spacing: .15rem;
      font-size: 1.2rem;

      &:not(.dispo) {
        border-color: $color9;
        background-color: $color9;
        cursor: not-allowed;
      }

      .icon {
        font-size: 2.8rem;
        margin-right: 1rem;
      }

      .textaddcart, .textnondispo, .textrupture {
        display: none;
      }
    }

    button[type="submit"].dispo {
      .textaddcart {
        display: inline-block;
      }
    }

    button[type="submit"].indispo {
      .textnondispo {
        display: inline-block;
      }
    }

    button[type="submit"].rupture {
      .textrupture {
        display: inline-block;
      }
    }
  }
}

.tabs-article {
  margin-top: 5rem;
  margin-bottom: 5rem;

  .nav-tabs {
    display: flex;
    justify-content: center;
    border-top: .2rem solid $color6;
    border-bottom: .2rem solid $color6;

    .nav-item {
      display: flex;
      justify-content: center;
      margin-bottom: 0;

      .nav-link {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        color: $color6;
        font-size: 1.6rem;
        font-weight: 700;
        text-transform: uppercase;
        border: none;
        height: 3.9rem;
        width: 28rem;
        text-align: center;
        border-radius: 0;

        &:after {
          position: absolute;
          left: 50%;
          bottom: -1.5rem;
          transform: translateY(-50%);
          content: "";
          display: block;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 1rem .85rem 0 .85rem;
          border-color: transparent transparent transparent transparent;
          transition: border-color .3s ease-in;
        }

        .icon {
          font-size: 2rem;
          margin-right: 1rem;
        }

        &:focus {
          opacity: 1;
        }

        &.active {
          opacity: 1;
          color: $color1;
          background-color: $color8;

          &::after {
            border-color: #74000a transparent transparent transparent;
          }
        }
      }
    }
  }

  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    border: none;
  }

  .tab-pane {
    color: $color9;
  }

}

.autre-produit {
  background-color: $color4;
  padding-top: 5rem;
  padding-bottom: 5rem;
  margin-bottom: 8rem;

  .h2 {
    font-size: 3.2rem;
    text-align: center;
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 5rem;

    .icon {
      margin-left: 1.5rem;
    }
  }

  .autre-produit-slider {
    width: calc(100% - 15rem);
    margin-right: auto;
    margin-left: auto;

    .card-product {
      width: calc(100% - 2rem);
      margin-right: 1rem;
      margin-left: 1rem;
    }

    .slick-prev {
      left: -7rem;
    }

    .slick-next {
      right: -7rem;
    }
  }
}

@media (max-width: $breakpoint-xl) {
  .achat-produit-c {
    padding: 2rem;
  }
  .qt-submit-block {
    flex-wrap: wrap;

    button {
      margin-top: 2rem;
      width: 100%;
    }
  }
  .autre-produit .autre-produit-slider {
    width: calc(100% - 5rem);
  }
}

@media (max-width: $breakpoint-lg) {
  .autre-produit {
    padding-top: 3rem;
    padding-bottom: 1rem;
    margin-bottom: 4rem;

    .autre-produit-slider {
      width: calc(100% - 2rem);
    }
  }
}

@media (max-width: $breakpoint-md) {
  .page-article #chemin {
    margin-bottom: 0;
    min-height: 3rem;
  }
  .bloc-slider-nav {
    margin-top: 2rem;
    margin-bottom: 2rem;
    text-align: center;

    .slider-nav-container {
      max-width: 30rem;
    }

    .slider-nav {
      max-width: 30rem;
      margin-left: auto;
      margin-right: auto;

      .slick-track .slick-slide {
        max-width: 7rem;
      }
    }
  }
  .info-produit-c {
    order: -1;
  }
  .achat-produit-c #panier .qt-submit-block {
    .qt-block {
      width: 100%;
    }

    button {
      max-width: 30rem;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .tabs-article .nav-tabs .nav-item {
    width: calc(100% / 3);

    .nav-link {
      width: 100%;
      font-size: 1.4rem;
    }
  }
}

@media (max-width: $breakpoint-sm) {
  .tabs-article .nav-tabs .nav-item .nav-link {
    line-height: 1.1;
  }
  .autre-produit .h2 {
    margin-bottom: 2.4rem;
  }
}

@media (max-width: $breakpoint-xs) {
  .tabs-article .nav-tabs .nav-item {
    width: 100%;

    .nav-link::after {
      content: none;
    }
  }
  .autre-produit .h2 {
    font-size: 2.2rem;
  }
}