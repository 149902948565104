hr,
.sep {
  @include sep;
}

/* Séparation invisible CMS et BUILDER EditeurTexte */
.sep-vertical {
  display: inline-block;
  height: 2rem;
  width: .1rem;
  margin: 0 .8rem -.2rem;
  background-color: $color8;
}

.sep-horizontal {
  display: block;
  height: .1rem;
  width: 100%;
  background-color: $color3;
}

hr.light,
.sep-horizontal-short {
  display: block;
  height: .1rem;
  width: 50%;
  min-width: 30rem;
  background-color: $color3;
  margin: 3rem auto;
}

.sep-horizontal-mobile {
  display: none;
  width: 25rem;
  height: .1rem;
  background-color: $color3;
  margin: 1.5rem auto 0;
}

.grey-sep-full {
  height: .1rem;
  width: 100%;
  background-color: $color3;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

@media (max-width: $breakpoint-sm) {
  .sep-horizontal-mobile {
    display: block;
  }
}