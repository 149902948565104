/* NEW */
.espace-client .button + .button {
  margin-left: 1.5rem;
}

.card-home-ec {
  margin-top: 1rem;
  margin-bottom: 1rem;

  .h2 {
    @include flex-center;
    color: $color1;
    text-transform: uppercase;
    font-size: 1.4rem;
    font-weight: 700;
    background: $color8;
    height: 3.5rem;
    transition: opacity .3s ease-in-out;

    &:focus,
    &:hover {
      opacity: .8;
    }
  }

  .card-home-ec-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .icon {
      font-size: 3.5em;
      color: #303432;
      margin-right: 1.5rem;
    }
  }
}

.ec-card {
  background-color: $color3;
  padding: 3rem;
}

/* Espace personnel, tableaux */
.table-espace-client {
  width: 100%;
  margin-top: 3rem;

  thead {
    background-color: $color3;
  }

  tr {
    text-align: center;
    height: 5.5rem;
    border-bottom: .2rem solid $color4;
  }

  tr:nth-child(2n) {
    background-color: $color3;
  }

  th, td {
    text-align: center;
    padding: 1rem .5rem;
  }

  .icon-eye {
    font-size: 2.8rem;
    line-height: 1;
  }

  .detail-nom {
    text-align: left;
    padding-left: 1.5rem;
    text-transform: uppercase;

    a {
      color: $color8;
      font-size: 1.6rem;
      font-weight: 700;
    }

    .ref {
      font-size: 1.4rem;
      font-weight: 600;
      color: #777;
    }
  }

  .total-detail {
    font-weight: 700;
  }
}

/* Page détail de commande */
.totalshopline {
  text-align: right;
  margin-top: 1.5rem;
  font-weight: 700;
  font-size: 2.2rem;

  span + span {
    margin-left: 1.5rem;
  }
}

.list-achat-card {
  margin-top: 1.5rem;

  > div {
    margin-top: 1.5rem;
  }
}

.achat-card {
  height: 100%;
  padding: 1.5rem;
  background-color: $color3;

  .h3 {
    text-align: center;
  }

  .button-red {
    padding: 1rem 2.5rem;
  }

  .achat-paiement-cta {
    margin-top: 1.5rem;
    text-align: center;
  }
}

/* Page paniers sauvegardés */
.paniers-rec {
  .liste-paniers-rec {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 0;
    max-width: 50rem;
    margin-right: auto;
    margin-left: auto;

    li {
      @include flex-between;
      height: 6rem;
      padding-left: 2rem;
      padding-right: 2rem;
      margin-bottom: 1rem;
      background-color: $color4;

      .liste-paniers {
        @include flex-center;

        a.icon-link {
          color: $color6;
          font-size: 2.6rem;
          margin-left: 1.5rem;

          &:hover {
            color: $color8;
          }

          + a {
            margin-left: 1.5rem;
          }
        }
      }
    }
  }
}

/* Module questions */
.list-questions {
  list-style-type: none;
}

.reponse {
  background-color: $color1;
}

.espace-client {
  ul {
    list-style-type: none
  }

  h2 {
    margin: 2rem 0 1rem 0;
  }
}

.erreur {
  color: #dd2a00;
  font-weight: 400;
}

@media (max-width: $breakpoint-xs) {
  button.submit,
  input[type=button],
  input[type=submit] {
    min-width: 100%;
    margin-bottom: 1rem;
    margin-top: 1rem;
  }

  .formline,
  .submitline {
    line-height: 3rem;
  }

}

