.gotop {
  position: fixed;
  bottom: 10rem;
  right: 4rem;
  border-radius: 50%;
  color: $color1;
  background-color: $color9;
  opacity: .85;
  width: 4rem;
  height: 4rem;
  line-height: 2.2;
  text-align: center;
  display: none;
  z-index: 65;
  cursor: pointer;
  font-size: 1.8rem;
  transition: all .3s ease-in-out;

  &:hover {
    background-color: $color8;
  }
}

@media (max-width: $breakpoint-sm) {
  .gotop {
    right: 3rem;
    bottom: 3rem;
  }
}

@media (max-width: $breakpoint-xs) {
  .gotop {
    right: 1rem;
    bottom: 2rem;
  }
}