#cms .content .button,
.button,
.button.lien-off {
  display: inline-block;
  font-family: 'Sora', sans-serif;
  color: $color1;
  background-color: $color8;
  border: .1rem solid $color8;
  font-size: 2rem;
  font-weight: 700;
  border-radius: .8rem;
  padding:  2.2rem 1.6rem;
  line-height: 1;
  cursor: pointer;
  text-align: center;
  transition: all .3s ease-in-out;

  &:hover,
  &:focus {
    color: $color8;
    background-color: $color1;
  }

  &.button-small {
    font-size: 1rem;
    padding: .6rem 1.2rem;
  }

  &.button-big {
    font-size: 1.8rem;
    padding: 2.4rem 5rem;
  }

  &.button-outline {
    color: $color8;
    background-color: $color1;

    &:hover,
    &:focus {
      color: $color1;
      background-color: $color8;
    }
  }
  &.button-secondary{
    background-color: $color9;
    border: .1rem solid $color9;
    &:hover,
    &:focus {
      color: $color9;
      background-color: #fff;
    }
  }
}

@media (max-width: $breakpoint-lg) {
  #cms .content .button,
  .button{
    padding: 1.4rem 1rem;
  }
}
