.log-error {
  text-align: left;
  color: #d8000c;
  font-weight: 700;

  li {
    line-height: 2rem;
    margin: .5rem 0;
  }

  .picto {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    line-height: 2rem;
    margin-right: .5rem;
    text-align: center;
    border: .1rem solid #d8000c;
    border-radius: 50%;
  }
}

.error-border {
  border: .2rem solid #d8000c !important;
}

.error {
  display: inline-block;
  color: $color1;
  background-color: #a72430;
  margin: 1rem 0;
  padding: .8rem;
}

.success {
  display: inline-block;
  color: $color2;
  background-color: #00b229;
  margin: 1rem 0;
  padding: .8rem;
}

input[type="submit"],
.button[type="submit"] {
  cursor: pointer;
}

/* Validation form */
.valid-form {
  input[type="email"],
  input[type="text"],
  input[type="password"],
  input[type="date"],
  textarea,
  select {
    display: block;
    border: .1rem solid #dee2e6;
    padding-left: 1.2rem;

    &:focus {
      border-color: #a4afba;
      outline: none;
      box-shadow: transparent;
    }

    &.valid {
      box-shadow: 0 0 .15rem .1rem green;
    }

    &.invalid {
      box-shadow: 0 0 .15rem .1rem #781629;
    }
  }

  input[type="checkbox"],
  input[type="radio"]{
    cursor: pointer;
  }

  input[type="checkbox"] ~ label,
  input[type="radio"] ~ label {
    margin-left: .8rem;
    cursor: pointer;
    margin-bottom: 0;
    line-height: 1.3;
  }

  textarea {
    width: 100%;
    resize: none;
    height: 15rem;
    padding-top: .5rem;
  }

  em.valid,
  em.invalid,
  .error-form {
    display: block;
    margin-top: .2rem;
    padding-left: 1.2rem;
    font-size: 1.2rem;
    font-family: 'OpenSans', sans-serif;
    font-style: italic;
  }

  .error-form,
  em.invalid {
    color: #781629;
  }

  em.invalid + em.valid {
    display: none;
  }

  .list-checkbox .form-checkbox-container {
    display: inline-block;

    input[type="checkbox"] ~ label {
      margin-left: 0;
      margin-right: .5rem;
    }
  }

  .form-radio-container,
  .checkbox-container {
    position: relative;

    em {
      position: absolute;
      top: 2rem;
      left: 0;
    }
  }
}

/* Focus effect et largeur sur les inputs */
.custom-form {
  input[type="text"],
  input[type="email"],
  input[type="password"],
  select {
    display: block;
    height: 5.5rem;
    width: 100%;
    border: .1rem solid #bbb;
    border-radius: .4rem;
    padding: 1.5rem 1.5rem .7rem 1.2rem;
    z-index: 1;
    &:disabled {
      border-color: #eee;
    }
  }

  input[type="file"]{
    max-width: 100%;
  }
  select {
    padding-top: .7rem;
    color: #888;
    &.valid{
      color: #434447;
    }
    option {
      color: #434447;
      &:disabled{
        color: #888;
      }
    }
  }

  textarea {
    display: block;
    width: 100%;
    border: .1rem solid #bbb;
    border-radius: .4rem;
    padding: 1.5rem 1.5rem .7rem 1.2rem;
    resize: none;
    margin-top: .3rem;
  }

  .form-width{
    position: relative;
    max-width: 39rem;
    text-align: left;
    margin-left: auto;
    margin-bottom: 2rem;
    margin-right: auto;
  }

  .input-effect .icon {
      margin-right: .6rem;
  }

  .focus-effect {
    padding: .7rem 1.5rem;
    border: .1rem solid $color4;
    position: relative;
    background: transparent;
  }

  .focus-effect ~ label {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 5.5rem;
    width: 100%;
    color: #888;
    border-radius: .4rem;
    background-color: $color1;
    margin-bottom: 0;
    padding-left: 1.2rem;
    padding-top: 1.6rem;
    transition: all .2s linear;
    z-index: 0;
    letter-spacing: .5px;
  }

  .focus-effect:focus ~ label,
  .has-content.focus-effect ~ label {
    top: 0;
    font-size: 11px;
    color: #888;
    padding-top: .2rem;
    transition: all .2s linear;
  }

  .form-radio-container {
    margin-bottom: 2rem;
    max-width: 39rem;
    margin-right: auto;
    margin-left: auto;

    .libelle {
      margin-right: 2rem;
    }

    input ~ input {
      margin-left: 2rem;
    }
  }

  .form-checkbox-container {
    max-width: 50rem;
    margin-left: auto;
    margin-right: auto;

    label {
      display: inline;
    }
  }
}
