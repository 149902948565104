/* HEADER */
#header {
  position: relative;
  height: 10.4rem;
  .header-content {
    @include flex-center;
    height:100%;
  }
}

.header-content {
  @include box-shadow;

  .container {
    @include flex-around;
    height: 100%;
  }

  .left-col {
    .header-logo img {
      width: 18rem;
    }
  }

  .right-col {
    @include flex-end;
    width: calc(100% - 30rem);
    margin-right: .5rem;
  }
}

/* Bandeau top header (bribe de texte) */
.top-header{
  color: #fff;
  background-color: $color8;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  font-size: 2rem;
  p{
    margin-bottom: 0;
  }
}

/* Sticky */
#header.sticky .level1 > .menu-item > a,
#header.sticky .level1 > .menu-item > .lien-off,
#header.sticky .header-content {
  height: 5rem;
}

#header.sticky .menu-item a.button{
  height: auto;
  padding: 1rem;
}

#header.sticky .header-content {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: $color1;
  z-index: 1001;
  border-bottom: none;
  box-shadow: 0 .2rem 2rem 0 rgba(0, 0, 0, .3);

  .logo {
    width: 15rem;
  }

}


@media (max-width: $breakpoint-xl) {
  .header-content{
    .left-col .header-logo img{
      width: 12rem;
    }
    .right-col {
      width: calc(100% - 18rem);
    }
  }
}

@media (max-width: $breakpoint-lg) {
  .header-content{
    .left-col .header-logo img {
      width: 22rem;
    }
    .right-col {
      display: none;
    }
  }
}

@media (max-width: $breakpoint-sm) {
}

@media (max-width: $breakpoint-xs) {
  .header-content{
    .left-col .header-logo img {
      width: 18rem;
    }
    .right-col {
      width: calc(100% - 16rem);
    }
  }
}

