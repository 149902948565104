.textcenter {
  text-align: center;
}

.textright {
  text-align: right;
}

.collapse-box {
  margin-bottom: 4rem;

  .collapse-item {
    margin: 1rem auto 1rem 2.5rem
  }
}

.min-height {
  min-height: 60rem;
  @include flex-center;
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.mw-1000 {
  max-width: 100rem;
  margin-left: auto;
  margin-right: auto;
}

.bold {
  font-weight: 700;
}

.small {
  font-size: 80%;
}

.desktop {
  display: block;
}

p{
  line-height: 1.6;
}

.mobile {
  display: none;
}

.grey-block {
  background-color: $color5;
  padding: 2rem;
}

.blue-block {
  background-color: $color11;
  padding: 2.4rem 1.6rem;
  text-align: center;
  border-radius: .8rem;
  border: .2rem solid transparent;
  .desc{
    color: #9BA2AF;
    margin-top: 1.5rem;
  }
  &:hover{
    background-color: #FFFFFF;
    border-color: $color8;
  }
}

/* Toast */
.alert-container .alert {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  color: $color1;

  &.alert-danger {
    background-color: #f35958;
  }

  &.alert-success {
    color: #155724;
    background-color: #d4edda;
  }
}

/* Sticky contact */
.sticky-contact{
  position: fixed;
  right: 0;
  top: 54%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  width: 7rem;
  z-index: 400;
  filter: drop-shadow(-19px 57px 24px rgba(0, 0, 0, 0.01)) drop-shadow(-11px 32px 20px rgba(0, 0, 0, 0.02)) drop-shadow(-5px 14px 15px rgba(0, 0, 0, 0.03)) drop-shadow(-1px 4px 8px rgba(0, 0, 0, 0.04)) drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.04));
  .sticky-block{
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-family: 'Sora', sans-serif;
    font-size: 1rem;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: .4rem;
    transition: all .3s;
    .icon{
      font-size: 3.6rem;
      line-height: 1;
      transition: all .3s;
    }
  }
  .sticky-phone,
  .sticky-phone.lien-off {
    color: #fff;
    background-color: $color8;
    &:hover{
      .icon{
        color: $color9;
      }
    }
  }
  .sticky-block-mobile{
    display: none;
  }
  .sticky-boutique{
    .icon{
      color: $color9;
    }
    color: $color10;
    background-color: #fff;
    &:hover{
      color: #fff;
      background-color: $color8;
    }
  }
}

// Permet de garder le ratio.
// Pour changer le ratio (carré par défaut), ajouter sur l'élément container avec la classe ratio un style="--ratio: 16/9"
.ratio {
  position: relative;
}

.ratio::before {
  content: '';
  display: block;
  padding-bottom: calc(100% / (var(--ratio, calc(1))));
}

.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  background-size: contain;
}
.ratio.cover > * {
  object-fit: cover;
  background-size: cover;
}

/* cf mixin.scss */
.absolute-container {
  @include absolute-container;
}

.flex-center {
  @include flex-center;
}

.flex-between {
  @include flex-between;
}

.flex-around {
  @include flex-around;
}

.cover-img {
  @include cover-img;
}

.box-shadow-light {
  @include box-shadow-light;
}

/* chemin img */
/* # {$base-img-url} */
$base-img-url: "../img/theme";

@media (min-width: $breakpoint-xl) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: $breakpoint-xl;
  }
}

@media (max-width: $breakpoint-lg) {
  .blue-block{
    padding: 2.4rem .5rem;
  }
  .sticky-contact{
    display: none;
  }
}

@media (max-width: $breakpoint-sm) {
  .container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .desktop {
    display: none;
  }
  .mobile {
    display: block;
  }
}
