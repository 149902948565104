.projet-contenu{
  position: relative;
  &::before{
    content: '';
    position: absolute;
    right: -9rem;
    bottom: 0;
    display: block;
    background-image: url('../img/theme/bg-vertical-projet.svg');
    background-repeat: no-repeat;
    background-size: contain;
    aspect-ratio: 890/1092;
    width: 100%;
    max-width: 90rem;
    z-index: -1;
  }
  .liste-etape{
    max-width: 800px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 3rem;
  }
  .card-etape{
    width: calc(40% - 1.2rem);
    &:first-child{
      background-color: #fff;
      .num-etape {
        background-color: #f1f7e6;
      }
    }
    &:nth-child(2){
      background-color: #f1f7e6;
      .num-etape {
        background-color: #fff;
      }
    }
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(6){
      width: calc(60% - 1.2rem);
      height: 75%;
    }
  }
  .bloc-cta-projet{
    text-align: center;
    margin-top: 10rem;
    .title-picto{
      justify-content: center;
    }
    .button{
      margin-top: 4rem;
      padding-left: 3rem;
      padding-right: 3rem;
      .icon{
        margin-right: 1rem;
      }
    }
  }
}

@media (max-width: $breakpoint-xxl) {
  .projet-contenu::before {
    max-width: 80rem;
  }
}

@media (max-width: $breakpoint-xl) {
  .projet-contenu::before {
    max-width: 50rem;
    top: 50%;
    transform: translateY(-50%);
  }
}

@media (max-width: $breakpoint-lg) {
  .projet-contenu::before {
    position: static;
    aspect-ratio: 1800 / 334;
    max-width: calc(100% - 3rem);
    background-image: url('../img/theme/bg-horizontal-projet.svg');
    transform: none;
    margin: 1rem auto 3rem;
  }
  .projet-contenu .liste-etape{
    max-width: none;
  }
}

@media (max-width: $breakpoint-sm) {
  .projet-contenu{
    .bloc-cta-projet {
      margin-top: 2rem;
    }
    .card-etape {
      width: calc(40% - .5rem);
      padding: 1rem;
      margin-bottom: 1rem;
    }
  }
  .projet-contenu .card-etape:nth-child(2),
  .projet-contenu .card-etape:nth-child(3),
  .projet-contenu .card-etape:nth-child(6){
    width: calc(60% - .5rem);
  }
}

@media (max-width: $breakpoint-xs) {
  .projet-contenu .card-etape{
    width: 100%;
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(6){
      width: 100%;
    }
    &:nth-child(2n){
      background-color: #f1f7e6;
      .num-etape {
        background-color: #fff;
      }
    }
  }
  .projet-contenu .bloc-cta-projet .button{
    padding-left: 2rem;
    padding-right: 2rem;
  }
}