.page-references {
  .slider-banner {
    margin-bottom: 0;
    .single-slider {
      margin-bottom: 0 !important;
    }
    .islide {
      display: none;
    }
  }
  .bloc-references {
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      .bloc-reference {
        border: .2rem solid #fff;
        .overlay {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          padding: 2em;
          transition: background-color 0.2s ease-in-out, opacity 0.2s ease-in-out;
          background-color: rgba(241, 120, 75, 0);
          z-index: 10;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          .title {
            font-size: 1.6rem;
            color: $color1;
            font-weight: bold;
          }
          @media (max-width: 500px) {
            .page-references .bloc-references ul .bloc-reference .overlay{
              padding: 1.2rem;
            }
          }
        }
        img {
          display: block;
          width: 100%;
          min-width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    .pagination {
      display: none;
    }
  }
  .categories-menu {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-left: -.5rem;
    margin-right: -.5rem;
    @media (max-width: 1400px) {
      justify-content: flex-start;
    }
    @media (max-width: 500px) {
      justify-content: center;
    }
    > a {
      display: flex;
      align-items: center;
      font-size: 1.5rem;
      transition: all 0.3s ease;
      padding: 1.6rem 1rem;
      text-transform: uppercase;
      margin-left: .5rem;
      margin-right: .5rem;
      margin-bottom: 1rem;
      @media (max-width: 1000px) {
        font-size: 1.4rem;
        padding: 1.4rem .8rem;
      }
      @media (max-width: 500px) {
        font-size: 1.3rem;
        width: 100%;
        justify-content: center;
        max-width: 260px;
      }
      &.act {
        color: $color9;
        border-color: $color9;
        pointer-events: none;
      }
    }
  }
  .liste-references {
    padding-top: 3rem;
  }
  .bas-references {
    padding: 0 4rem 8rem;

    >.container {
      display: flex;
      justify-content: center;
      .button{
        margin-top: 6rem;
      }
    }
  }
  .min-height {
    flex-direction: column;
  }
  .news-bas {
    .buttons {
      margin-top: 0;
    }
  }
  .menu-references {
    padding-bottom: 0;
    >.container {
      @media (max-width: 767px) {
        max-width: 100%;
      }
    }
  }
}

@media (max-width: 400px) {
  .page-references .bas-references > .container .button{
    font-size: 1.6rem;
    padding: 1rem;
    line-height: 1.3;
  }
}
