/* Loader css*/
.cssloader,
.cssloader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}

.cssloader {
  margin: 6rem auto;
  font-size: 1rem;
  position: relative;
  text-indent: -9999rem;
  border-top: 1.1em solid rgba(255, 255, 255, .2);
  border-right: 1.1em solid rgba(255, 255, 255, .2);
  border-bottom: 1.1em solid rgba(255, 255, 255, .2);
  border-left: 1.1em solid $color8;
  background-color: $color4;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load 1.1s infinite linear;
  animation: load 1.1s infinite linear;
}

@-webkit-keyframes load {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

