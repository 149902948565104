#chemin {
  position: relative;
  overflow: hidden;
  min-height: 4rem;
  margin-top: 1rem;
  margin-bottom: 1rem;

  a {
    color: $color9;
    margin-right: 1rem;

    &:hover {
      color: $color8;
    }
  }

  .breadcrumb-sep {
    margin-right: 1rem;
  }
  @media (max-width: $breakpoint-sm) {
    font-size: 80%;
  }
}