.menu {
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .level1 {
    display: flex;
    justify-content: space-around;

    > .menu-item {
      padding-top: 1.6rem;
      padding-bottom: 1.6rem;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > a,
      > .lien-off {
        color: $color10;
        &:focus,
        &:hover{
          color: $color8;
        }
        &.button{
          color: #fff;
          transition: padding 0s, color .3s, background-color .3s;
          padding: 2.5rem 4.2rem;
          &:focus,
          &:hover{
            color: $color8;
          }
        }
      }
      a, span, .lien-off {
        font-family: 'Sora', sans-serif;
        font-weight: 600;
        font-size: 1.9rem;
      }
    }
  }

  &-item {
    @include flex-center;
    transition: background .3s ease-in-out;

    &:hover span {
      /*cursor: default;*/
    }

    a,
    .lien-off {
      @include flex-center;
      width: 100%;
    }
  }
}

/* Colone droite : Sous menu */

.blur-content #cd-main #contenu {
  filter: blur(.6rem);
  transition: all .3s ease-in-out;
}

nav.menu ul.level1 > li.dropdown{
  &::after{
    content: "\EA08";
    font-family: 'kfont';
    display: inline-block;
    font-size: 1.6rem;
    margin-left: 0.8rem;
    color: rgba(33, 47, 67, 0.5);
    transition: transform .3s;
  }
}

nav.menu ul.level1 > li.dropdown:hover{
  &::after{
    transform: rotate(90deg);
  }
  > .level2 {
    opacity: 1;
    visibility: visible;
  }
}

nav.menu .level2 {
  position: absolute;
  top: 10.4rem;
  right: 0;
  left: 0;
  background-color: #333434;
  cursor: initial;
  opacity: 0;
  visibility: hidden;
  transition: opacity .3s, visibility .3s;
  z-index: 99;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  > .row{
    margin-left: 0;
    margin-right: 0;
  }
  .title_smenu{
    position: relative;
    @include flex-center;
    min-height: 16.4rem;
    text-align: center;
    a, span, .lien-off {
      font-size: 3.5rem;
      font-weight: 400;
      color: #fff;
    }
    a:hover,
    .lien-off:hover {
      color: $color8;
    }
    &::after {
      content: '';
      position: absolute;
      top: 2.6rem;
      right: 0;
      display: inline-block;
      width: .1rem;
      height: calc(100% - 4.8rem);
      background-color: #fff;
    }
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 100%;
    margin-top: auto;
    margin-bottom: auto;
    padding-left: 6rem;
    li{
      text-align: left;
      padding-top: .5rem;
      padding-bottom: .5rem;
      a, .lien-off {
        color: #fff;
        &:hover{
          color: $color8;
        }
      }
    }
  }
}

#header.sticky nav.menu{
  .level1 > .menu-item {
    padding-bottom: 0;
    padding-top: 0;
  }
  .level2 {
    top: 5rem;
  }
}

@media (max-width: $breakpoint-xxl) {
  .menu .level1 > .menu-item > a.button,
  .menu .level1 > .menu-item > .lien-off.button {
    padding: 2rem 1.5rem;
  }
  .menu .level1 > .menu-item{
    margin-left: 1rem;
    margin-right: 1rem;
    a,
    span,
    .lien-off {
      font-size: 1.6rem;
    }
  }
}

@media (max-width: $breakpoint-xl) {
  .menu .level1 > .menu-item {
    margin-left: .8rem;
    margin-right: .8rem;
    a,
    span,
    .lien-off {
      font-size: 1.5rem;
    }
  }
}

