.page-cms,
.liste-content {
  h1, h2, h3, h4, h5, h6,
  .h1, .h2, .h3, .h4, .h5, .h6 {
    @include title-global;
  }

  h1,
  .h1 {
    @include h1;
    margin-bottom: 2rem;
  }

  h2,
  .h2 {
    @include h2;
    display: flex;
    align-items: center;
    font-size: 2.6rem;
    margin-top: 2.5rem;
    margin-bottom: 1rem;
    img{
      object-fit: contain;
      margin-right: .8rem;
    }
  }

  h3,
  .h3 {
    @include h3;
    font-size: 2.4rem;
    margin-top: 1.5rem;
  }

  h4,
  .h4 {
    @include h4;
  }
}